'use client';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InstagramIframe from './InstagramIframe';

const INSTAGRAM_MODAL_KEY = 'instagram-modal-closed';
const DISPLAY_DELAY = 60000; // 1 minute in milliseconds
const MOBILE_BREAKPOINT = 768; // Standard tablet/mobile breakpoint

const InstagramModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };

    // Check immediately
    checkMobile();

    // Add resize listener
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    dialogRef.current?.close();
    localStorage.setItem(INSTAGRAM_MODAL_KEY, 'true');
  };

  useEffect(() => {
    const wasModalClosed = localStorage.getItem(INSTAGRAM_MODAL_KEY) === 'true';
    // Only proceed if we're on mobile and modal hasn't been closed before
    if (!wasModalClosed && isMobile) {
      const isVisibleTimer = setTimeout(() => {
        setIsVisible(true);
        dialogRef.current?.showModal();
        closeButtonRef.current?.focus();
      }, DISPLAY_DELAY);
      const isRenderedTimer = setTimeout(() => {
        setIsRendered(true);
      }, DISPLAY_DELAY - 2000);

      return () => {
        clearTimeout(isVisibleTimer);
        clearTimeout(isRenderedTimer);
      };
    }
  }, [isMobile]); // Now depends on isMobile state

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isVisible) {
        handleClose();
      }
    };

    if (isVisible) {
      window.addEventListener('keydown', handleEscKey);
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isVisible]);

  // Don't render anything if we're on desktop or if modal was previously closed
  if (
    typeof window === 'undefined' ||
    localStorage.getItem(INSTAGRAM_MODAL_KEY) === 'true' ||
    !isMobile
  ) {
    return null;
  }

  return (
    <dialog
      ref={dialogRef}
      aria-labelledby="instagram-dialog-title"
      className={`[inset-block-start:auto] m-0 max-w-full max-h-full fixed bottom-0 left-0 right-0 w-full transform transition-transform duration-500 bg-gray-400 ease-in-out p-4 border-0 z-50 ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className="w-full max-w-md mx-auto relative">
        <button
          ref={closeButtonRef}
          onClick={handleClose}
          className="absolute -top-2 right-0 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded"
          aria-label="Close dialog"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 id="instagram-dialog-title" className="text-2xl font-bold mb-4">
          <FormattedMessage id="follow-me-on-instagram" defaultMessage={'Follow me on Instagram'} />
        </h2>
        {isRendered && (
          <InstagramIframe
            url="https://www.instagram.com/offbeatbudapest/embed?cr=1&v=14&wp=1080"
            lazy={false}
          />
        )}
      </div>
    </dialog>
  );
};

export default InstagramModal;
