'use client';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

function LanguageDetector({
  localePaths,
}: {
  localePaths: { locale: string; fullPath: string }[];
}) {
  useEffect(() => {
    const langParam = new URLSearchParams(document.location.search).get('lang');
    if (!langParam) {
      return;
    }
    const hasLocalePrefix = document.location.pathname.split('/')[1].length === 2;
    const pathLocale = hasLocalePrefix ? document.location.pathname.split('/')[1] : 'en';
    if (pathLocale !== langParam) {
      const localePath = localePaths.find((path) => path.locale === langParam);
      if (localePath) {
        navigate(localePath.fullPath, { replace: true });
      }
    }
  }, [localePaths]);
  return null;
}

export default LanguageDetector;
