import React from 'react';

// checks if the url contains /embed in the path, if not, adds it
const appendEmbed = (url: string) => {
  const urlObj = new URL(url);
  const path = urlObj.pathname;
  if (!path.includes('/embed')) {
    // check if the path ends in /
    urlObj.pathname = path.endsWith('/') ? `${path}embed` : `${path}/embed`;
  }
  return urlObj.toString();
};

function InstagramIframe({ url = '', lazy = true }): React.ReactElement<{
  url: string;
  lazy: boolean;
}> {
  const embedUrl = appendEmbed(url);
  return (
    <iframe
      className="instagram-media instagram-media-rendered"
      id="instagram-embed-0"
      src={embedUrl}
      allowFullScreen={false}
      data-instgrm-payload-id="instagram-media-payload-0"
      loading={lazy ? 'lazy' : 'eager'}
      style={{
        background: 'white',
        width: 'calc(100% - 2px)',
        borderRadius: 3,
        border: '1px solid rgb(219, 219, 219)',
        boxShadow: 'none',
        display: 'block',
        margin: '18px 0px 12px',
        maxHeight: '820px',
        minWidth: 326,
        padding: 0,
        aspectRatio: '938 / 1032',
      }}
    />
  );
}

export default InstagramIframe;
