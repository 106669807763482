import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getDefaultLocalePath } from '../lib/getDefaultLocalePath';

const LangSelector = ({
  className,
  paths,
  currentLocale,
}: {
  className?: string;
  paths: { locale: string; fullPath: string }[];
  currentLocale: string;
}) => {
  return (
    <div
      className={classNames('bg-white justify-center items-center pt-1 4xl:flex hidden', className)}
    >
      {paths.map((path, index) => (
        <React.Fragment key={path.locale}>
          <Link
            to={getDefaultLocalePath(path.fullPath, path.locale)}
            className={classNames('uppercase text-sm px-4 py-1', {
              'text-gray-500': path.locale !== currentLocale,
              'hover:underline hover:underline-offset-4': path.locale !== currentLocale,
              'pointer-events-none': path.locale === currentLocale,
            })}
            onClick={() => {
              document.documentElement.lang = path.locale.split('-')[0];
            }}
          >
            <FormattedMessage id={path.locale} />
          </Link>
          {index !== paths.length - 1 && <div className="w-[1px] h-[1em] bg-gray-300" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LangSelector;
