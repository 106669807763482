exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-zh-budapest-city-guide-zh-guide-to-ruin-bars-budapest-tsx": () => import("./../../../src/pages/zh/budapest-city-guide/zh-guide-to-ruin-bars-budapest.tsx" /* webpackChunkName: "component---src-pages-zh-budapest-city-guide-zh-guide-to-ruin-bars-budapest-tsx" */),
  "component---src-pages-zh-places-zh-szimpla-kert-ruin-bar-tsx": () => import("./../../../src/pages/zh/places/zh-szimpla-kert-ruin-bar.tsx" /* webpackChunkName: "component---src-pages-zh-places-zh-szimpla-kert-ruin-bar-tsx" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-features-js": () => import("./../../../src/templates/features.js" /* webpackChunkName: "component---src-templates-features-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-places-js": () => import("./../../../src/templates/places.js" /* webpackChunkName: "component---src-templates-places-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-recipes-js": () => import("./../../../src/templates/recipes.js" /* webpackChunkName: "component---src-templates-recipes-js" */),
  "component---src-templates-toplist-js": () => import("./../../../src/templates/toplist.js" /* webpackChunkName: "component---src-templates-toplist-js" */),
  "component---src-templates-toplists-tsx": () => import("./../../../src/templates/toplists.tsx" /* webpackChunkName: "component---src-templates-toplists-tsx" */)
}

