export const getLocale = (data: any, path: string) => {
  const dataLocale =
    data?.page?.node_locale || data?.page?.locale || data?.context?.locale || data?.locale;
  if (dataLocale) {
    return dataLocale;
  }
  const pathLocale = path.split('/')[1];
  if (pathLocale.length === 2) {
    return pathLocale;
  }
  return 'en-US';
};
